// app/javascript/recipes/index.js

import './filtering'
import './comments/index'

document.addEventListener('DOMContentLoaded', function () {
  // New Recipe Modal
  $('.new_recipe').on('click', function () {
    $('#recipe_modal').modal('show')
  })

  $('.publish_recipe').on('click', function () {
    $(this).addClass('loading')
    window.recipeForm.$children[0]
      .publishRecipe()
      .then(() => $(this).removeClass('loading'))
      .catch(() => $(this).removeClass('loading'))
  })
})

function readURL(input, element) {
  if (input.files && input.files[0]) {
    const reader = new FileReader()

    reader.onload = function (e) {
      hoverPlaceholder()
      $(element).attr('src', e.target.result)
    }

    reader.readAsDataURL(input.files[0])
  }
}

// Icookin hover link description on recipe filters
// $('.icookin-popup__text').popup({
//   inline: true,
//   hoverable: true,
//   position: 'bottom left',
//   lastResort: true,
// })

// Badges hover link description on user show
$('.product-badge__text').popup({
  inline: true,
  hoverable: true,
  position: 'bottom left',
  lastResort: true,
})

// Recipe verified hover link description on recipe list
$('.recipe-verified-popup__text').popup({
  inline: true,
  hoverable: true,
  position: 'top right',
  lastResort: true,
})

// Mobile recipe filters menu
$('.recipe-filters__mobile').on(
  'click',
  '.recipe-filters__toggle',
  function () {
    $('.recipe-filters__dropdowns').slideToggle('slow')
    let $icon = $('.recipe-filters__toggle').find('i')

    if ($icon.hasClass('down')) {
      $icon.removeClass('down').addClass('up')
      $('.recipe-filters__dropdowns').removeClass('mobile-hidden--important')
    } else {
      $icon.removeClass('up').addClass('down')
      $('.recipe-filters__dropdowns').addClass('mobile-hidden--important')
    }
  }
)

// Add hover bloc on image upload on recipe form
function hoverPlaceholder(e) {
  $('.js-containerimgUpload').css('z-index', '9')
  $('.js-containerimgUpload').parent().addClass('js-imgUploaded')
}

// Display recipe image on upload
$('.imgUpload').change(function () {
  readURL(this, '.js-containerimgUpload')
  $('.ui.label.pointer').remove()
})

if ($('body').hasClass('action_edit')) {
  hoverPlaceholder()
}

// Display user profil image on upload
$('.inputfile').change(function () {
  readURL(this, '.img-profile .profil-image img')
})
