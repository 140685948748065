// app/javascript/recipes/filtering/components/icookin.js

const icookin = {
  icookinCheckbox: $('.checkbox.icookin_recipes:visible'),
  isIcookableCheckbox: () => {
    const icookinInput = icookin.icookinCheckbox.find('input:nth-child(1)')
    if (icookinInput.prop('checked')) {
      return '&is_icookable=true'
    }
    return ''
  },
}

export default icookin
