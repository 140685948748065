
document.addEventListener('DOMContentLoaded', () => {
  const Isotope = require('isotope-layout');
  const isotopeContainer = document.querySelector('.isotope_user_recipes_filter');
  const stampElem = document.querySelector('.stamp_item_user_recipes');
  if (isotopeContainer) {
    const iso = new Isotope(isotopeContainer, {
      itemSelector: '.element-item',
      layoutMode: 'masonry',
      sortAscending: true,
      sortBy: 'is_drafted',
      masonry: {
        isFitWidth: true,
        gutter: 100,
      },
      getSortData: {
        name: '.name',
        created_at: '[data-created_at]',
        updated_at: '[data-updated_at]',
      },
      stamp: '.stamp_item_user_recipes'
    });
    $('.user_recipes_filter').dropdown({
      onChange(val, text, $choice) {
        const sortByValue = $choice.attr('data-sort-by');
        if (sortByValue === 'name') {
          iso.arrange({
            sortBy: sortByValue, sortAscending: true,
          });
        } else {
          iso.arrange({
            sortBy: sortByValue, sortAscending: false,
          });
        }
        iso.stamp(stampElem);
      },
    });
  }
});
