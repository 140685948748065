// app/javascript/recipes/filtering/actions/categories.js

import globalActions from './global.js.erb';
import components from '../components';

const categories = {
  setDropdown: () => {
    components.categories.dropdown.dropdown({
      action: 'nothing',
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // Confirm hide dropdown
        return true;
      },
    });
  },
  eraseCheckboxes: () => {
    components.categories.semanticCheckedCheckboxes().map((index, checkbox) => {
      const input = checkbox.querySelector('input:nth-child(1)');
      input.checked = false;
      checkbox.classList.remove('checked');
      return true;
    });
  },
  eraseCheckbox: (target) => {
    const filter = target.textContent;
    const checkedCategory = components.categories.semanticCheckedCheckboxes().filter(`[data-category-label='${filter}']`);
    checkedCategory.find('input:nth-child(1)').prop('checked', false);
    checkedCategory.removeClass('checked');
    target.remove();
    globalActions.getFilteredRecipes('replace');
  },
};

export default categories;
