// app/javascript/recipes/filtering/components/others.js

import components from '../components';

const others = {
  dropdown: $('.ui.dropdown.filtering_others'),
  header: $('h3.source_others'),
  validateBtn: $('div.filtering.validate.others'),
  eraseBtn: $('div.filtering.erase.others'),
  checkedOthers: () => {
    if(components.verified.isVerifiedCheckbox() || components.difficulty.difficultyFilter().length > 0 || components.price.priceFilter().length > 0 || components.users.userFilter().length > 0) {
      others.dropdown.addClass('custom-dropdown--active');
    } else {
      others.dropdown.removeClass('custom-dropdown--active');
    }
  }
};

export default others;
