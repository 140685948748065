// app/javascript/recipes/filtering/actions/index.js

import categories from './categories';
import prepare_time from './prepare_time';
import thematics from './thematics';
import verified from './verified';
import icookin from './icookin';
import price from './price';
import difficulty from './difficulty';
import ingredients from './ingredients.js.erb';
import products from './products.js.erb';
import others from './others';
import order from './order';
import users from './users.js.erb';
import global from './global.js.erb';

const actions = {
  thematics,
  categories,
  verified,
  icookin,
  ingredients,
  products,
  difficulty,
  price,
  others,
  order,
  users,
  prepare_time,
  global,
};

export default actions;
