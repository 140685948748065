// app/javascript/recipes/filtering/components/users.js


const users = {
  searchDiv: $('.search.users.filtering'),
  header: $('h3.source_users'),
  searchInput: $('input.users_filtering_input'),
  selectedUserInput: $('input.user_selected'),
  selectedUserValue: () => users.selectedUserInput.val(),
  deleteFilterBtn: () => $('.ui.label[data-classname="user"]'),
  validateBtn: $('.button.validate.filtering.users'),
  eraseBtn: $('.button.erase.filtering.users'),
  userFilter: () => {
    const userFilter = `&users=${users.selectedUserValue()}`;
    return users.selectedUserValue() === '' ? '' : userFilter;
  },
};

export default users;
