// app/javascript/recipes/filtering/actions/ingredients.js

import globalActions from './global.js.erb';
import components from '../components';
//import 'semantic-ui-sass';

const ingredients = {
  setDropdown: () => {
    components.ingredients.dropdown.dropdown({
      fullTextSearch: true,
      action(text) {
        const name = 'ingredient';
        components.ingredients.dropdown.find(`.item.ingredient[data-value="${text}"]`).hide();
        components.ingredients.ingredientsFilteredList.append(`<div class='ui label' data-classname="${name}">${text}<i class='delete icon filter'></i></div>`);
      },
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // confirm hide dropdown
        return true;
      },
    });
  },
  setSearch: () => {
    components.ingredients.searchInput.search({
      minCharacters: 3,
      results: '.results',
    });
  },
  eraseFilter: (target) => {
    const filter = target.textContent;
    components.ingredients.dropdown.find(`.item.ingredient[data-value="${filter}"]`).show();
    target.remove();
    const list = document.querySelector('.ingredients_filtered_list');
    const labels = list.querySelectorAll('.label[data-classname="ingredient"]');
    labels.forEach((e, i, list) => {
      if (e.textContent === filter) e.remove();
    });
  },
  hideSelected: () => {
    if (document.querySelector('.ingredients_filtered_list')) {
      const list = document.querySelector('.ingredients_filtered_list');
      const labels = list.querySelectorAll('.label[data-classname="ingredient"]');
    // labels is nodeList
    labels.forEach((e, i, list) => {
      components.ingredients.dropdown.find(`.item.ingredient[data-value="${e.textContent}"]`).hide();
    })}
  },
  eraseAllIngredients: () => {
    const els = components.ingredients.ingredientsFilteredList.find('.ui.label');
    els.map((index, el) => ingredients.eraseFilter(el));
  },
};

export default ingredients;
