// app/javascript/recipes/filtering/triggers/prepare_time.js

import prepare_time from '../components/prepare_time.js.erb';
import actions from '../actions';


document.addEventListener('DOMContentLoaded', () => {
  actions.prepare_time.setDropdown();
  prepare_time.validateBtn.on('click', () => {
    prepare_time.dropdown.dropdown('hide');
  });

  prepare_time.eraseBtn.on('click', () => {
    actions.prepare_time.reinitializePrepareTime();
    prepare_time.dropdown.dropdown('hide');
  });

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return false;
    if (target.getAttribute('data-classname') !== 'prepare_time') return false;
    target.remove();
    actions.prepare_time.reinitializePrepareTime();
    return true;
  });
});
