// app/javascript/recipes/filtering/actions/verified.js

import components from '../components';

const verified = {
  setCheckbox: () => {
    components.verified.checkbox.checkbox();
  },
  eraseCheckbox: () => {
    components.verified.checkbox.find('input:nth-child(1)').prop('checked', false);
    components.verified.checkbox.removeClass('checked');
    return true;
  },
};

export default verified;
