// app/javascript/recipes/filtering/components/prepare_time.js


const prepare_time = {
  dropdown: $('.ui.dropdown.filtering_prepare_time'),
  selected: () => prepare_time.dropdown.find('.checkbox.prepare_time.checked input'),
  validateBtn: $('div.filtering.validate.prepare_time'),
  eraseBtn: $('div.filtering.erase.prepare_time'),
  deleteFilterBtn: () => $('.ui.label[data-classname="prepare_time"]'),
  prepareTimeFilter: () => {
    let filter = '&prepare_time=';
    filter += prepare_time.selected().val();
    if(prepare_time.selected().val() != undefined) {
      prepare_time.dropdown.addClass('custom-dropdown--active');
      return filter;
    }
    prepare_time.dropdown.removeClass('custom-dropdown--active');
    return '';
  },
  prepareTimeTranslation: () => {
    let translation;
    if (prepare_time.selected().val() == '5') translation = "moins de 5 min"
    if (prepare_time.selected().val() == '10') translation = "moins de 10 min"
    if (prepare_time.selected().val() == '20') translation = "moins de 20 min"
    if (prepare_time.selected().val() == '30') translation = "moins de 30 min"
    if (prepare_time.selected().val() == '45') translation = "moins de 45 min"
    if (prepare_time.selected().val() == '60') translation = "moins d'une heure"
    return translation;
  }
};

export default prepare_time;
