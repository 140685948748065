// app/javascript/recipes/filtering/actions/products.js

import globalActions from './global.js.erb';
import components from '../components';

const products = {
  setDropdown: () => {
    components.products.dropdown.dropdown({
      fullTextSearch: true,
      action(text) {
        const name = 'product';
        const prodArr = text.split(" ");
        const ref = prodArr[prodArr.length - 1]
        components.products.dropdown.find(`.item.product[data-value='${ref}']`).hide();
        components.products.productsFilteredList.append(`<div class='ui label' data-value="${ref}" data-classname="${name}">${text}<i class='delete icon filter'></i></div>`);
      },
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // confirm hide dropdown
        return true;
      },
    });
  },
  setSearch: () => {
    components.products.searchInput.search({
      minCharacters: 3,
      results: '.results',
    });
  },
  eraseFilter: (target) => {
    const filter = target.textContent.split(" ")[target.textContent.split(" ").length - 1];
    components.products.dropdown.find(`.item.product[data-value="${filter}"]`).show();
    target.remove();
    const list = document.querySelector('.products_filtered_list');
    const labels = list.querySelectorAll('.label[data-classname="product"]');
    labels.forEach((e, i, list) => {
      if (e.textContent.split(" ")[e.textContent.split(" ").length - 1] === filter) e.remove();
    });
  },
  hideSelected: () => {
    if (document.querySelector('.products_filtered_list')) {
      const list = document.querySelector('.products_filtered_list');
      const labels = list.querySelectorAll('.label[data-classname="product"]');
    // labels is nodeList
    labels.forEach((e, i, list) => {
      components.products.dropdown.find(`.item.product[data-value="${e.textContent.split(" ")[e.textContent.split(" ").length - 1]}"]`).hide();
    })}
  },
  eraseAllProducts: () => {
    const els = components.products.productsFilteredList.find('.ui.label');
    els.map((index, el) => products.eraseFilter(el));
  },
};

export default products;
