// app/javascript/recipes/filtering/triggers/icookin.js

import components from '../components'
import actions from '../actions'

document.addEventListener('DOMContentLoaded', () => {
  actions.icookin.setCheckboxes()
  components.icookin.icookinCheckbox.on('click', () => {
    actions.global.getFilteredRecipes('replace')
  })

  document.addEventListener('click', (e) => {
    let target
    if (e.target.classList.value.includes('ui label')) target = e.target
    if (e.target.classList.value.includes('delete icon filter'))
      target = e.target.parentNode
    if (target == null) return false
    if (target.getAttribute('data-classname') !== 'is_icookable') return false
    actions.icookin.eraseCheckboxes()
    target.remove()
    actions.global.getFilteredRecipes('replace')
    return true
  })
})
