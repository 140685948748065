// app/javascript/recipes/filtering/components/global.js

import helpers from '../../../../helpers';

const global = {
  filtersListWrap: () => document.querySelector('.filters-list__wrap'),
  pageNumber: (pageNumber) => {
    return pageNumber === undefined ? '' : '&page=' + pageNumber;
  },
  lockedParameter: () => {
    const lockedValue = helpers.getUrlParameter('lock');
    if (lockedValue) {
      return `&lock=${lockedValue}`;
    }
    return '';
  },
  searchTerm: () => {
    let searchTermFilter = '&search_term=';
    const searchTerm = helpers.getUrlParameter('search_term');
    searchTermFilter += searchTerm;
    if (searchTerm) return searchTermFilter;
    return '';
  },
  reinitializeAllBtn: () => document.querySelector('.erase-all'),
};

export default global;