// app/javascript/users/index.js

import './follow_ups';
import './recipes/sorting';

document.addEventListener('DOMContentLoaded', () => {
  const button = $('button.user_relationship');
  const followed_slug = button.data("user-slug");
  const contactButton = $('button.contact-button');
  const text_create = button.data("text-create");
  const text_destroy = button.data("text-destroy");
  let successMessageCreate = "Abonné à"
  let successMessageDestroy = "Désabonné de"

  button.on('click', () => {
    if (button.data('relationship') == "create") {
      $.ajax({
        url: `/profil/${followed_slug}/create_user_relationship`,
        headers: {"X-CSRF-Token": $.token},
        type: 'POST',
        statusCode: {
          204: function (jqXHR) {
            button.html(text_destroy);
            button.data('relationship', 'destroy');
            globalFlashMessage('success', `${successMessageCreate}&nbsp;${followed_slug} <i class="check icon"></i>`);
          }
        }
      });
    } else {
      $.ajax({
        url: `/profil/${followed_slug}/destroy_user_relationship`,
        headers: {"X-CSRF-Token": $.token},
        type: 'DELETE',
        statusCode: {
          204: function (jqXHR) {
            button.html(text_create);
            button.data('relationship', 'create');
            globalFlashMessage('success', `${successMessageDestroy}&nbsp;${followed_slug} <i class="check icon"></i>`);
          }
        }
      });
    }
  });

  contactButton.on('click', () => {
    $.popsellchat.popsellchat('open_chatroom_ambassador_customer_id', contactButton.data("id"));
    $('#popsellchat_online_items').toggleClass('visible');
    $('.close_popsellchat_online_items').toggleClass('visible');
  })
  $('.close_popsellchat_online_items').on('click', () => {
    $('#popsellchat_online_items').toggleClass('visible');
    $('.close_popsellchat_online_items').toggleClass('visible');
  })
})

// Display phone number on btn click on User profile
document.addEventListener('DOMContentLoaded', () => {
  const button = $('.js-phone-button');
  const user_phone = button.data("phone");
  const text = $('.js-phone-text');

  $(button).one('click', function(e) {
    e.preventDefault();
    button.attr('href', `tel:${user_phone}`);
    text.text(user_phone);
  });
})
