// app/javascript/recipes/filtering/triggers/users.js

import actions from '../actions';

document.addEventListener('DOMContentLoaded', () => {
  actions.users.setSearch();
  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return;
    if (target.getAttribute('data-classname') !== 'user') return;
    actions.users.eraseFilter(target);
    target.remove();
    actions.global.getFilteredRecipes('replace');
  });
});
