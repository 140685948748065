// jQuery polyfill $(window).load
jQuery.fn.load = function(callback) { $(window).on("load", callback) };

// Init isotope
$(window).load(function() {
  const Isotope = require('isotope-layout');
  const isotopeContainer = document.querySelector('.isotope');
  if (isotopeContainer) {
    const iso = new Isotope(isotopeContainer, {
      itemSelector: '.element-item',
      layoutMode: 'fitRows',
      sortAscending: false,
      sortBy: 'updated_at',
      getSortData: {
        name: '.name',
        created_at: '[data-created_at]',
        updated_at: '[data-updated_at]',
      },
    });
    $('.favorite_list_filter').dropdown({
      onChange(val, text, $choice) {
        const sortByValue = $choice.attr('data-sort-by');
        if (sortByValue === 'name') {
          iso.arrange({
            sortBy: sortByValue, sortAscending: true,
          });
        } else {
          iso.arrange({
            sortBy: sortByValue, sortAscending: false,
          });
        }
      },
    });
  }
});
