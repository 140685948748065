// app/javascript/users/follow_ups/components/index.js


const components = {
  followUpsModal: () => $('div.follow_ups.modal.longer.ui'),
  followersModal: () => $('div.followers.modal.longer.ui'),
  followUpsButton: () => $('p.follow_ups_link'),
  followersButton: () => $('p.followers_link'),
  followUpsCount: () => $('p.follow_ups_link.update'),
  followersCount: () => $('p.followers_link.update'),
  unfollowBtns: () => $('button.unfollow.btn'),
  followBtns: () => $('button.follow.btn'),
};

export default components;
