// app/javascript/recipes/filtering/components/product_categories.js

const productCategories = {
  productCategoryFilter: () => {
    let categories = new URLSearchParams(location.search).get('product_categories')
  
    const filter = !!categories ? `&product_categories=${categories}` : ''

    return filter
  },
}

export default productCategories
