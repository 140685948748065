// app/javascript/recipes/filtering/actions/global.js
import components from '../components';
import actions from '.';

import helpers from '../../../../helpers';

const global = {
  hideLockedParameter: () => {
    const lockedValue = helpers.getUrlParameter('lock');
    let from;
    if (lockedValue === 'categories') {
      components.categories.deleteFilterBtn().hide();
      components.categories.header.show();
      components.thematics.header.hide();
      components.users.header.hide();
      components.categories.dropdown.hide();
    } else if (lockedValue === 'thematics') {
      components.thematics.deleteFilterBtn().hide();
      components.thematics.header.show();
      components.categories.header.hide();
      components.users.header.hide();
      components.thematics.dropdown.hide();
    } else if (lockedValue === 'users') {
      components.users.deleteFilterBtn().hide();
      components.users.header.show();
      components.thematics.header.hide();
      components.categories.header.hide();
      components.users.searchDiv.hide();
    } else {
      components.thematics.header.hide();
      components.categories.header.hide();
    }
  },
  buildFilter: (pageNumber) => {
    let filter = '/recettes.js?';
    components.others.checkedOthers();
    filter += components.global.searchTerm();
    filter += components.categories.checkedCategories();
    filter += components.thematics.checkedThematics();
    filter += components.verified.isVerifiedCheckbox();
    filter += components.icookin.isIcookableCheckbox();
    filter += components.global.lockedParameter();
    filter += components.ingredients.ingredientsList();
    filter += components.products.productsList();
    filter += components.global.pageNumber(pageNumber);
    filter += components.difficulty.difficultyFilter();
    filter += components.prepare_time.prepareTimeFilter();
    filter += components.price.priceFilter();
    filter += components.users.userFilter();
    filter += components.order.orderingFilter();
    filter += components.productCategories.productCategoryFilter();
    return filter;
  },
  getFilteredRecipes: (html, pageNumber) => {
    global.randombg();
    const filter = global.buildFilter(pageNumber);
    $.ajax({
      url: filter,
      contentType: 'application/javascript',
      headers: { 'X-CSRF-Token': $.token },
      type: 'GET',
      statusCode: {
        200: (jqXHR) => {
          global.processAjaxData(jqXHR, filter, html);
          $('.loaders').find('.cookie, .drink, .carotte-cut, .dots').removeClass('visible');
          $('.recipes_list').removeClass('loading');
          $('.js-favorite-list-modal-trigger').on('click', (e) => {
            const recipeData = $(e.currentTarget).data('recipe')

            let $modal = window.favoriteListModal.$children[0]

            $modal.recipe = recipeData
            $modal.open = true
          })
          // Trigger weeklyMenuModal
          $('.js-weekly-menu-modal-trigger').on('click', (e) => {
            const recipeData = $(e.currentTarget).data('recipe')

            let $modal = window.weeklyMenuModal.$children[0]

            $modal.recipe = recipeData
            $modal.open = true
          })
          // Trigger shoppingListModal
          $('.js-shopping-list-trigger').on('click', (e) => {
            const recipeData = $(e.currentTarget).data('recipe')
            const groupId = $(e.currentTarget).data('group-id')

            let $modal = window.shoppingListModal.$children[0]

            $modal.recipe = recipeData
            $modal.selectedIngredientOptions = groupId
            $modal.open = true
          })
        },
        422: () => {

        },
      },

    });
  },
  processAjaxData: (response, filter, html) => {
    // Remove page number from path (this cause bugs on pasted links)
    // This replace the url without reloading page
    window.history.pushState({}, '', filter.replace('.js', ''));
    // This replace the html without reloading page
    if (html === 'replace') {
      $('.recipes_list').html('');
      $('.recipes_list').html(response);
      $('button.more-recipes').show();
      global.reBuildFiltersList();
    } else if (html === 'append') {
      $('.recipes_list').append(response);
    } else if (html === 'prepend') {
      const el = document.querySelector('.column.noselect.stretched')
      $('.recipes_list').prepend(response);
      el.scrollIntoView({behavior: "smooth", block: "end"});
    }
  },
    randombg: () => {
      var random = Math.floor(Math.random() * 4) + 0;
      var gif = [".cookie", ".drink", ".carotte-cut", ".dots"];
      $('.recipes_list').addClass('loading');
      $('.loaders').find(".dots").addClass('visible');
    },
  addFilterInDom: (name, filter) => {
    $('.filters_list').append(`<div class='ui label' data-classname="${name}"><h2 class="filter-name">${filter}</h2><i class='delete icon filter'></i></div>`);
    components.global.filtersListWrap().classList.add('visible');
  },
  reBuildFiltersList: () => {
    $('.filters_list').html('');
    const productCategoriesP = helpers.getUrlParameter('product_categories');
    const categoriesP = helpers.getUrlParameter('categories');
    const thematicsP = helpers.getUrlParameter('thematics');
    const isIcookable = helpers.getUrlParameter('is_icookable');
    const isVerified = helpers.getUrlParameter('is_verified');
    const ingredients = helpers.getUrlParameter('ingredients');
    const products = helpers.getUrlParameter('products');
    const difficulty = helpers.getUrlParameter('difficulty');
    const prepareTime = helpers.getUrlParameter('prepare_time');
    const price = helpers.getUrlParameter('price');
    const users = helpers.getUrlParameter('users');
    if (categoriesP && helpers.getUrlParameter('lock') !== 'categories') {
      $.each(categoriesP.split(','), (i, v) => global.addFilterInDom('category', decodeURIComponent(v)));
    }

    if (productCategoriesP && helpers.getUrlParameter('lock') !== 'product_categories') {
      $.each(productCategoriesP.split(','), (i, v) => global.addFilterInDom('product_category', decodeURIComponent(v)));
    }

    if (thematicsP && helpers.getUrlParameter('lock') !== 'thematics') {
      $.each(thematicsP.split(','), (i, v) => global.addFilterInDom('thematic', decodeURIComponent(v)));
    }

    if (isIcookable) {
      let isIcookableText;
      isIcookableText = isIcookable === 'true' ? "Recettes i-Cook'in" : "Recettes classiques";
      global.addFilterInDom('is_icookable', isIcookableText);
    }

    if (isVerified) {
      global.addFilterInDom('is_verified', "Recettes vérifiées");
    }
    if (prepareTime) {
      global.addFilterInDom('prepare_time', components.prepare_time.prepareTimeTranslation());
    }

    if (ingredients) {
      ingredients.split(',').map((v, i) => global.addFilterInDom('ingredient', decodeURIComponent(v)));
    }

    if (products) {
      products.split(',').map((v, i) => global.addFilterInDom('product', decodeURIComponent(v)));
    }

    if (difficulty) {
      global.addFilterInDom('difficulty', components.difficulty.difficultyTranslation());
      components.difficulty.enumText().text(components.difficulty.difficultyTranslation());
    } else {
      components.difficulty.enumText().text(components.difficulty.selectDifficultyTranslation());
    }

    if (price) {
      global.addFilterInDom('price', components.price.priceTranslation());
      components.price.enumText().text(components.price.priceTranslation());
    } else {
      components.price.enumText().text(components.price.selectPriceTranslation());
    }

    if(users) global.addFilterInDom('user', components.users.selectedUserValue());
  },
  reinitializeAllFilters: () => {
    actions.ingredients.eraseAllIngredients();
    actions.products.eraseAllProducts();
    actions.icookin.eraseCheckboxes();
    if (helpers.getUrlParameter('lock') !== 'categories') {
      actions.categories.eraseCheckboxes();
    }
    if (helpers.getUrlParameter('lock') !== 'thematics') {
      actions.thematics.eraseCheckboxes();
    }
    actions.difficulty.reinitializeDifficulty();
    actions.prepare_time.reinitializePrepareTime(false);
    actions.price.reinitializePrice();
    actions.verified.eraseCheckbox();
    actions.users.eraseFilter();
    actions.global.getFilteredRecipes('replace');
    components.thematics.header.hide();
    components.categories.header.hide();
  }
};

export default global;
