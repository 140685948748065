// app/javascript/recipes/filtering/actions/others.js

import globalActions from './global.js.erb';
import priceActions from './price';
import verifiedActions from './verified';
import difficultyActions from './difficulty';
import usersActions from './users.js.erb';
import components from '../components/others';

const others = {
  setDropdown: () => {
    components.dropdown.dropdown({
      action: 'nothing',
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // Confirm hide dropdown
        return true;
      },
    });
  },
  reinitializeFilters: () => {
    difficultyActions.reinitializeDifficulty();
    priceActions.reinitializePrice();
    verifiedActions.eraseCheckbox();
    usersActions.eraseFilter();
    components.dropdown.dropdown('hide');
  },
};

export default others;
