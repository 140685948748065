// app/javascript/recipes/filtering/triggers/verified.js

import actions from '../actions';

document.addEventListener('DOMContentLoaded', () => {
  actions.verified.setCheckbox();

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return false;
    if (target.getAttribute('data-classname') !== 'is_verified') return false;
    actions.verified.eraseCheckbox();
    actions.global.getFilteredRecipes('replace');
    target.remove();
    return true;
  });
});
