document.addEventListener('DOMContentLoaded', function () {
  initCommentsTab();
  handleRating();
  displayAnswerForm();
  validateForm();
});

function initCommentsTab() {
  $('.recipe-comments__tab .item')
    .tab({
      onVisible: function() {
        let value = $(this).data('tab');

        // Set comment is_problem to true on report problem tab
        if(value === 'report') {
          $('.recipe-comment__form').find('#is_problem').val(true);
        } else {
          $('.recipe-comment__form').find('#is_problem').val(false);
        }
      }
    });
}

function handleRating() {
  $('.comment-rating').find('input[type="radio"]').on({
    mouseenter: function () {
      $('.comment-rating__value').text(ratingTranslation($(this).val()));
    },
    mouseleave: function() {
      // Check if a rating value is defined
      if(!$('.comment-rating').find('input[type="radio"]').is(':checked')) {
        $('.comment-rating__value').text('Évaluez la recette');
      } else {
        let checkedVal = $('.comment-rating').find('input[type="radio"]:checked').val();
        $('.comment-rating__value').text(ratingTranslation(checkedVal));
      }
    },
    click: function() {
      // Clean previous checked attr
      $('.comment-rating').find('input[type="radio"]').each(function() {
        $(this).attr('checked', false);
      })

      // Set current radio button checked attr
      $(this).attr('checked', 'checked');
      $(this).parent().addClass('selected');

      // Display radio button value on the right
      $('.comment-rating__value').text(ratingTranslation($(this).val()));
    }
  });
}

function displayAnswerForm() {
  $(document).on('click', 'a[id*="reply-btn"]',function() {
    // Display reply form
    $(this).closest('.comment-wrap').find('.recipe-answer__form').toggle();

    // Reset left margin
    $(this).closest('.comment-wrap').find('.recipe-answer__content').css('margin-left', '0');

    // Add left margin if reply form is for subcomment
    if($(this).closest('.comment-wrap').find('.subcomments').length > 0) {
      $(this).closest('.comment-wrap').find('.recipe-answer__content').css('margin-left', '85px');
    }
  });
}

function validateForm() {
  let headerError = 'Erreur !';
  let contentError = 'Vous devez au moins évaluer la recette ou saisir un commentaire';

  // Force at least one field to be selected (rating or comment content)
  $('.tab[data-tab="comment"] .recipe-comment__form').submit(function(e) {
    if(!$('.comment-rating').hasClass('selected') && !$.trim($("#comment_content").val())) {
      $(this).prepend(
        `
          <div class="ui negative message" style="z-index: 1000;">
            <i class="close icon"></i>
            <div class="header">${headerError}</div>
            ${contentError}
          </div>
        `
      );

      return false;
    }
  });

  $('.message .close').on('click', function() {
      $(this).closest('.message').transition('fade');
  });
}

function ratingTranslation(element) {
  let translation;

  if (element === 'disappointing') translation = "Décevant";
  if (element === 'correct') translation = "Correct";
  if (element === 'good') translation = "Bon";
  if (element === 'very_good') translation = "Très bon";
  if (element === 'delicious') translation = "Délicieux";

  return translation;
}
