function reportModal(e) {
  // Get data type & id
  let data_type = $(e.target).data('type')
  let data_id = $(e.target).data('id')

  $('#report_modal')
    .modal({
      onApprove: function () {
        console.log('ICI: ', data_type, data_id)
        $('#report_modal.visible').find('#report_reported_type').val(data_type)
        $('#report_modal.visible').find('#report_reported_id').val(data_id)
        $(this).find('form').submit()
      },
    })
    .modal('show')
}

export default reportModal
