// app/javascript/recipes/filtering/components/price.js.erb


const price = {
  divElement: () => $('.price.filtering'),
  selected: () => price.divElement().find('i.icon.active'),
  enumText: () => $('.price-enum'),
  enumSelected: () => {
    let en;
    if (price.selected().length === 1) en = 'cheap';
    if (price.selected().length === 2) en = 'moderate';
    if (price.selected().length === 3) en = 'expensive';
    return en;
  },
  priceFilter: () => {
    let filter = '&price=';
    filter += price.enumSelected();
    return price.selected().length !== 0 ? filter : '';
  },
  priceTranslation: () => {
    let translation;
    if (price.enumSelected() === 'cheap') translation = "Peu cher";
    if (price.enumSelected() === 'moderate') translation = "Moyennement cher";
    if (price.enumSelected() === 'expensive') translation = "Très cher";
    return translation;
  },
  selectPriceTranslation: () => "Choisir un prix maximum",
};

export default price;
