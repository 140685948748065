// app/javascript/recipes/filtering/actions/thematics.js

import globalActions from './global.js.erb';
import components from '../components';

const thematics = {
  setDropdown: () => {
    components.thematics.dropdown.dropdown({
      action: 'nothing',
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // Confirm hide dropdown
        return true;
      },
    });
  },
  eraseCheckboxes: () => {
    components.thematics.semanticCheckedCheckboxes().map((index, checkbox) => {
      const input = checkbox.querySelector('input:nth-child(1)');
      input.checked = false;
      checkbox.classList.remove('checked');
      return true;
    });
  },
  eraseCheckbox: (target) => {
    const filter = target.textContent;
    const checkedThematic = components.thematics.semanticCheckedCheckboxes().filter(`[data-thematic-label='${filter}']`);
    checkedThematic.find('input:nth-child(1)').prop('checked', false);
    checkedThematic.removeClass('checked');
    target.remove();
    globalActions.getFilteredRecipes('replace');
  },
};

export default thematics;
