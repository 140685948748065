// app/javascript/recipes/filtering/components/order.js


const order = {
  dropdown: () => $('.ui.dropdown.filtering_order'),
  orderingValue: () => order.dropdown().find('.item.selected').data('value'),
  orderingFilter: () => {
    const filter = `&order=${order.orderingValue()}`;
    if (order.orderingValue() === undefined) return '';
    return filter;
  },
};

export default order;
