// app/javascript/recipes/filtering/triggers/price.js

import components from '../components/price.js.erb';
import actions from '../actions';

document.addEventListener('DOMContentLoaded', () => {
  actions.price.setRating();
  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return false;
    if (target.getAttribute('data-classname') !== 'price') return true;
    actions.price.reinitializePrice();
    actions.global.getFilteredRecipes('replace');
    target.remove();
    return true;
  });
});
