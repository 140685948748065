// app/javascript/recipes/filtering/components/categories.js


const categories = {
  dropdown: $('.ui.dropdown.filtering_categories'),
  semanticCheckboxes: $('.checkbox.categories'),
  semanticCheckedCheckboxes: () => $('.checkbox.categories.checked'),
  checkboxes: $('.checkbox.categories.non-semantic'),
  checkedCheckboxes: $('.checkbox.categories.non-semantic[checked]'),
  header: $('h3.source_categories'),
  validateBtn: $('div.filtering.validate.categories'),
  eraseBtn: $('div.filtering.erase.categories'),
  filtersList: document.querySelector('.filters_list'),
  deleteFilterBtn: () => $('.ui.label[data-classname="category"]'),
  checkedCategories: () => {
    let categoriesFilter = '&categories=';
    const selectedCategories = categories.semanticCheckedCheckboxes().map((index, checkbox) => checkbox.getAttribute('data-category-label')).get().join(',');
    if (selectedCategories !== '') {
      categories.dropdown.addClass('custom-dropdown--active');
      categoriesFilter += selectedCategories;
      return categoriesFilter;
    }
    categories.dropdown.removeClass('custom-dropdown--active');
    return '';
  },
};

export default categories;
