// app/javascript/recipes/filtering/triggers/others.js

import others from '../components/others';
import actions from '../actions';


document.addEventListener('DOMContentLoaded', () => {
  actions.others.setDropdown();
  others.validateBtn.on('click', () => {
    others.dropdown.dropdown('hide');
  });

  others.eraseBtn.on('click', () => {
    actions.others.reinitializeFilters();
    others.dropdown.dropdown('hide');
  });
});
