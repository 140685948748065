// app/javascript/recipes/filtering/triggers/categories.js

import categories from '../components/categories';
import actions from '../actions';


document.addEventListener('DOMContentLoaded', () => {
  actions.categories.setDropdown();
  categories.validateBtn.on('click', () => {
    categories.dropdown.dropdown('hide');
  });

  categories.eraseBtn.on('click', () => {
    actions.categories.eraseCheckboxes();
    categories.dropdown.dropdown('hide');
  });

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return false;
    if (target.getAttribute('data-classname') !== 'category') return false;
    actions.categories.eraseCheckbox(target);
    return true;
  });
});
