// app/javascript/recipes/filtering/components/difficulty.js


const difficulty = {
  divElement: () => $('.difficulty.filtering'),
  selected: () => difficulty.divElement().find('.custom-radio.checked input'),
  enumText: () => $('.difficulty-enum'),
  enumSelected: () => {
    let en;
    if (difficulty.selected().val() == 0) en = 'easy';
    if (difficulty.selected().val() == 1) en = 'medium';
    if (difficulty.selected().val() == 2) en = 'hard';
    return en;
  },
  difficultyFilter: () => {
    let filter = '&difficulty=';
    filter += difficulty.enumSelected();
    return difficulty.selected().val() < 3 ? filter : '';
  },
  difficultyTranslation: () => {
    let translation;
    if (difficulty.enumSelected() === 'easy') translation = "Facile";
    if (difficulty.enumSelected() === 'medium') translation = "Moyen";
    if (difficulty.enumSelected() === 'hard') translation = "Difficile";
    return translation;
  },
  selectDifficultyTranslation: () => "Choisir une difficulté maximum",
};

export default difficulty;
