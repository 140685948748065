// app/javascript/recipes/filtering/triggers/ingredients.js

import actions from '../actions';
import ingredients from '../components/ingredients';

document.addEventListener('DOMContentLoaded', () => {
  actions.ingredients.setDropdown();
  actions.ingredients.setSearch();
  actions.ingredients.hideSelected();
  ingredients.validateBtn.on('click', () => {
    ingredients.dropdown.dropdown('hide');
  });
  ingredients.eraseBtn.on('click', () => {
    // erase ingredients
    actions.ingredients.eraseAllIngredients();
    ingredients.dropdown.dropdown('hide');
  });

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return;
    if (target.getAttribute('data-classname') !== 'ingredient') return;
    actions.ingredients.eraseFilter(target);
    actions.global.getFilteredRecipes('replace');
  });
});
