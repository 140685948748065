// app/javascript/recipes/filtering/actions/users.js

import globalActions from './global.js.erb';
import components from '../components/users';
//import 'semantic-ui-sass';

const users = {
  setSearch: () => {
    components.searchDiv.search({
      type: 'category',
      minCharacters: 3,
      apiSettings: {
        url: '/profil/search.json?name={query}&order=relevance&nbr_elements=5',
        onResponse: (jsonResponse) => {
          const response = { results: {} };
          $.each(jsonResponse, (index, item) => {
            const classname = "Auteurs";
            if (response.results[classname] === undefined) {
              response.results[classname] = {
                results: [],
              };
            }
            if (item.profile == 'advisor') {
              response.results[classname].results.push({
                title: `${item.last_name} ${item.first_name}`,
                username: item.username,
                description: item.status,
                image: (item.image == null ? '/assets/empty_user.webp' : item.image),
              });
            } else {
              response.results[classname].results.push({
                title: item.username,
                username: item.username,
                image: (item.image == null ? '/assets/empty_user.webp' : item.image),
              });
            }
          });
          return response;
        },
      },
      onSelect(result) {
        components.selectedUserInput.val(result.username);
      },
    });
  },
  eraseFilter: () => {
    components.searchInput.val('');
    components.selectedUserInput.val('');
  },
};

export default users;
