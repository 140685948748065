// app/javascript/recipes/filtering/components/ingredients.js


const ingredients = {
  dropdown: $('.dropdown.ingredient.multiple'),
  searchInput: $('.search.ingredients.filtering'),
  validateBtn: $('.button.validate.filtering.ingredients'),
  eraseBtn: $('.erase.filtering.ingredients'),
  ingredientsFilteredList: $('.ingredients_filtered_list'),
  deleteFilterBtn: $('.ui.label[data-classname="ingredient"]'),
  ingredientsList: () => {
    const ingredientsFilter = '&ingredients=';
    const list = $('.ingredients_filtered_list');
    const filteredIngredients = [];
    list.contents().map((index, el) => filteredIngredients.push(el.textContent));
    ingredients.dropdown.removeClass('custom-dropdown--active');
    if (filteredIngredients.length === 0) {
      return '';
    } else {
      ingredients.dropdown.addClass('custom-dropdown--active');
    }
    return ingredientsFilter.concat(filteredIngredients.join(','));
  },
};

export default ingredients;
