// app/javascript/recipes/filtering/actions/prepare_time.js

import globalActions from './global.js.erb';
import components from '../components';

const prepare_time = {
  setDropdown: () => {
    components.prepare_time.dropdown.dropdown({
      action: 'nothing',
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // Confirm hide dropdown
        return true;
      },
    });
  },
  reinitializePrepareTime: (alone = true) => {
    // Clean previous checked attr
    components.prepare_time.selected().parent().removeClass('checked');
    components.prepare_time.selected().prop('checked');
    if(alone == true) {
      globalActions.getFilteredRecipes('replace');
    }
  }
};

export default prepare_time;
