// app/javascript/recipes/filtering/actions/difficulty.js

import components from '../components/difficulty.js.erb';

const difficulty = {
  reinitializeDifficulty: () => {
    // Clean previous checked attr
    components.selected().parent().removeClass('checked');
  },
};

export default difficulty;
