// app/javascript/recipes/filtering/triggers/products.js

import actions from '../actions';
import products from '../components/products';

document.addEventListener('DOMContentLoaded', () => {
  actions.products.setDropdown();
  actions.products.setSearch();
  actions.products.hideSelected();
  products.validateBtn.on('click', () => {
    products.dropdown.dropdown('hide');
  });
  products.eraseBtn.on('click', () => {
    // erase products
    actions.products.eraseAllProducts();
    products.dropdown.dropdown('hide');
  });

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return;
    if (target.getAttribute('data-classname') !== 'product') return;
    actions.products.eraseFilter(target);
    actions.global.getFilteredRecipes('replace');
  });
});
