// app/javascript/recipes/filtering/actions/icookin.js

import components from '../components'

const icookin = {
  setCheckboxes: () => {
    components.icookin.icookinCheckbox.checkbox()
  },
  eraseCheckboxes: () => {
    const icookinInput = components.icookin.icookinCheckbox.find(
      'input:nth-child(1)'
    )
    // checkboxes are unchecked by default
    icookinInput.prop('checked', false)

    return true
  },
}

export default icookin
