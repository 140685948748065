// app/javascript/recipes/filtering/components/products.js

const products = {
  dropdown: $('.dropdown.product.multiple'),
  searchInput: $('.search.products.filtering'),
  validateBtn: $('.button.validate.filtering.products'),
  eraseBtn: $('.erase.filtering.products'),
  productsFilteredList: $('.products_filtered_list'),
  deleteFilterBtn: $('.ui.label[data-classname="product"]'),
  productsList: () => {
    const productsFilter = '&products=';
    const list = $('.products_filtered_list');
    const filteredProducts = [];
    list.contents().map((index, el) => {
      return filteredProducts.push($(el).text())
    });
    filteredProducts.forEach((v, i) => filteredProducts[i] = encodeURIComponent(v));

    if (filteredProducts.length === 0) {
      products.dropdown.removeClass('custom-dropdown--active');
      return '';
    }
    products.dropdown.addClass('custom-dropdown--active');
    return productsFilter.concat(filteredProducts.join(','));
  },
};

export default products;
