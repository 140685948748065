// app/javascript/users/follow_ups/triggers/index.js

import components from '../components';
import actions from '../actions/index.js.erb';

document.addEventListener('DOMContentLoaded', () => {
  components.followUpsButton().on('click', () => {
    actions.openFollowUpsModal();
  });
  components.followersButton().on('click', () => {
    actions.openFollowersModal();
  });
  components.followBtns().on('click', function () { // eslint-disable-line func-names
    actions.followUser($(this));
  });
  components.unfollowBtns().on('click', function () { // eslint-disable-line func-names
    actions.unfollowUser($(this));
  });
});
