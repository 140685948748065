// app/javascript/recipes/filtering/actions/price.js

import components from '../components/price.js.erb';

const price = {
  setRating: () => {
    components.divElement().rating({
      namespace: 'price',
      maxRating: 3,
      clearable: true,
      fireOnInit: true,
      onRate(value) {
        if (value !== 0) components.enumText().text(components.priceTranslation());
        if (value === 0) components.enumText().text(components.selectPriceTranslation());
      },
    });
  },
  reinitializePrice: () => {
    components.selected().removeClass('active');
  },
};

export default price;
