// app/javascript/recipes/filtering/actions/order.js

import globalActions from './global.js.erb';
import components from '../components/order';

const order = {
  setDropdown: () => {
    components.dropdown().dropdown({
      onHide() {
        globalActions.getFilteredRecipes('replace');
        // Confirm hide dropdown
        return true;
      },
    });
  },
};

export default order;
