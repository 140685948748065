document.addEventListener('DOMContentLoaded', function () {
  $('input#search.general').keypress(function (e) {
    if (e.which == 13) {
      window.location.replace("/recettes?order=relevance&search_term=" + $(this).val().split(' ').join('+'));
    }
  });

  $('.ui.search.general.home').on('click', function () {
    initSearch(this, '.home')
  });

  $('.ui.search.general.navigation').on('click', function () {
    initSearch(this, '.navigation')
  });

  truncate_value = function (str, length = 37, ending = '...') {
    if (str != undefined){
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } else {
        return str;
      }
    }
  };

  function initSearch(context, input) {
    $(context)
      .search({
        type: 'autocomplete',
        minCharacters: 3,
        apiSettings: {
          onResponse: function (jsonResponse) {
            console.log(jsonResponse)
            return {
              results: {
                'search': {
                  name: " ",
                  results: [{
                    title: "Rechercher « " + '<span>' + $('input#search.general' + input).val() + '</span>' + " »  dans les recettes",
                    url: '/recettes?order=relevance&search_term=' + $('input#search.general' + input).val().split(' ').join('+')
                  }]
                },
                "recipe": {
                  name: "Recettes",
                  results: $.map(jsonResponse['recipe'], function (recipe) {
                    return {
                      title: recipe.title,
                      description: truncate_value(
                        $.map(recipe.categories.slice(0, 3), function(c) {
                          return c.label
                        }).join(', ')
                      ),
                      image: recipe.image,
                      url: recipe.html_url
                    }
                  })
                },
                "official_ingredient": {
                  name: "Ingrédients",
                  results: $.map(jsonResponse['official_ingredient'], function (ingredient) {
                    return {
                      title: truncate_value(ingredient.label),
                      image: ingredient.image,
                      url: ingredient.html_url
                    }
                  })
                },
                "user": {
                  name: "Membres",
                  results: $.map(jsonResponse['user'], function (user) {
                    if (["gdm_chef", "chef", "advisor"].includes(user.profile)) {
                      return {
                        title: truncate_value(user.name),
                        description: truncate_value(user.username),
                        image: user.image,
                        url: user.html_url
                      }
                    } else if (user.profile == "gdm") {
                      return {
                        title: truncate_value(user.first_name),
                        description: truncate_value(user.username),
                        image: user.image,
                        url: user.html_url
                      }
                    } else {
                      return {
                        title: truncate_value(user.username),
                        image: user.image,
                        url: user.html_url
                      }
                    }
                  })
                }
              }
            };
          },
          url: '/search.json?term={query}&order=relevance',
        }
      });
  }
})

$.fn.search.settings.templates['autocomplete'] = function(response) {
    const search = `
      <div class="category">
        <div class="name"> </div>
        <div class="results transition visible" style="display: flex !important;">
          <a class="result" href="${response.results.search.results[0].url}">
            <div class="content">
              <div class="title">${response.results.search.results[0].title}</div>
            </div>
          </a>
        </div>
      </div>
    `
    delete response.results.search;
    let categories = ``
    for (element in response.results){
      if(response.results[element].results.length == 0) { continue; }
      let results = ``
      for (result in response.results[element].results){
        let imageClasses = ""
        if(element == "User"){ imageClasses = "circular ui" }
        results += `
          <a class="result" href="${response.results[element].results[result].url}">
            <div class="image ${imageClasses}">
              <img src="${response.results[element].results[result].image}">
            </div>
            <div class="content">
              <div class="title">${response.results[element].results[result].title}</div>
            </div>
          </a>
        `
      }
      categories += `
      <div class="category">
        <div class="name">${response.results[element].name}</div>
          <div class="results transition visible" style="display: flex !important;">
          ${results}
        </div>
      </div>
      `
    }
    const html = `
      ${search}
      ${categories}
    `
    return html;
  }
