window.reloadPostAffiliateBanners = () => {
  let banners = {}

  $('.postaffiliate_banner').each((index, el) => {
    type = $(el).data('banner')
    banners[type] ||= $(el).html()
  })

  for (let bannerType of Object.keys(banners)) {
    $(`.postaffiliate_banner[data-banner=${bannerType}]`).html(
      banners[bannerType]
    ).show()
  }
}
