import createChannel from "./cable";

document.addEventListener('DOMContentLoaded', () => {
  let e = document.getElementById('recommendations_wrapper')

  if(e != null && e.getAttribute('data-recos') == "on"){
    const recos = createChannel("RecoAndTrendingRefreshChannel", {
      received({ message }) {
        e.innerHTML = message;
      }
    });
  }
});
