import helpers from '../../helpers'
import { initMobileNav } from '../layout/responsive'
import Glide from '@glidejs/glide'
import address from 'jquery-address'
import sharer from 'sharer.js'

window.Glide = Glide

document.addEventListener('DOMContentLoaded', function () {
  $.token = $('meta[name="csrf-token"]').attr('content')
  $('.ui.accordion').accordion()
  $('.ui.dropdown')
    .not('#speed_normal, #step_temperature__select, #step_accessories')
    .dropdown()
  $('.ui.dropdown.classic').dropdown({
    transition: 'fade',
  })
  $('.ui.dropdown.multiple').not('#step_accessories').dropdown()
  $('.no_fold').dropdown({
    action: 'nothing',
  })
  $('.ui.checkbox').checkbox()
  $('.users_form .item').tab({
    history: true,
    historyType: 'hash',
    onVisible: function (e) {
      if (window.location.hash != `#/${e}`) {
        // Flush flash messages on tab switch
        $('.ui.message').remove()
      }
    },
  })

  $('.menu-panel .item').tab()

  // Mobile navigation
  initMobileNav()

  // Toggle logo visiblity when search expand on mobile
  toggleLogo()

  // remove class for FeedTab
  toggleRightTab()

  // Limit line for textArea
  maxLinesForTextArea()

  // Hide top bar on scroll
  hideTopBarOnScroll()

  // Check if flash messages are display on #recipe/show & recipe form
  checkFlashMessages()

  // Init Popsell
  let popsellDom = document.getElementById('popsellchat_online_items')
  if (popsellDom != null) {
    setTimeout(function () {
      detectSizeForPopsellChat()
    }, 1000)
  }

  // Hide flash messages
  $(this).on('click', '.message .close', function () {
    $(this).closest('.message').transition('fade')

    // Remove translateY() on recipe show & form
    resetRecipeLayout()
  })

  // init Glide slider
  if (document.getElementById('products-slider')) {
    var glide = new Glide('#products-slider', {
      type: 'slider',
      perView: 3,
      gap: 35,
      bound: true,
      breakpoints: {
        992: {
          perView: 2,
        },
        767: {
          perView: 1,
        },
      },
    })

    glide.mount()
  }

  // init Glide slider mobile
  if (document.getElementById('products-slider-mobile')) {
    var glide = new Glide('#products-slider-mobile', {
      type: 'slider',
      perView: 3,
      gap: 35,
      bound: true,
      breakpoints: {
        992: {
          perView: 2,
        },
        767: {
          perView: 1,
        },
      },
    })

    glide.mount()
  }

  // init Glide slider mobile
  if (document.getElementById('headlines-slider-mobile')) {
    var glide = new Glide('#headlines-slider-mobile', {
      type: 'slider',
      perView: 3,
      gap: 35,
      bound: true,
      breakpoints: {
        992: {
          perView: 2,
        },
        767: {
          perView: 1,
        },
      },
    })

    glide.mount()
  }

  if (window.location.href.indexOf('comment-') > -1) {
    $('.primary-nav, .secondary-nav, .edit-bar').removeClass('visible-nav')
    $('.primary-nav, .secondary-nav, .edit-bar, .message-fixed').addClass(
      'hidden-nav'
    )
    $('html, body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - 100,
      },
      500
    )
  }

  ;(function (document, window, index) {
    var inputs = document.querySelectorAll('.inputfile')
    Array.prototype.forEach.call(inputs, function (input) {
      var label = input.nextElementSibling,
        labelVal = label.innerHTML

      input.addEventListener('change', function (e) {
        var fileName = ''
        if (this.files && this.files.length > 1)
          fileName = (this.getAttribute('data-multiple-caption') || '').replace(
            '{count}',
            this.files.length
          )
        else fileName = e.target.value.split('\\').pop()
        const shortText =
          $.trim(fileName).substring(0, 20).split(' ').slice(0, -1).join(' ') +
          '...'
        if (fileName) label.querySelector('span').innerHTML = shortText
        else label.innerHTML = labelVal
      })

      // Firefox bug fix
      input.addEventListener('focus', function () {
        input.classList.add('has-focus')
      })
      input.addEventListener('blur', function () {
        input.classList.remove('has-focus')
      })
    })
  })(document, window, 0)

  // Make globalFlashMessage() function accessible everywhere
  window.globalFlashMessage = globalFlashMessage

  // show popsell users
  $('.open_popsellchat_online_items').on('click', () => {
    $('#popsellchat_online_items').toggleClass('visible')
    $('.close_popsellchat_online_items').toggleClass('visible')
  })
})

$(document).on('turbolinks:load', function () {
  $('.ui.checkbox').checkbox()
})

// Limit line for textArea
function maxLinesForTextArea(element, context) {
  let newLines
  let lines = 10

  $('.maxLines').keydown(function (e) {
    newLines = $(this).val().split('\n').length
    if (e.keyCode == 13 && newLines >= lines) {
      return false
    }
  })
}

// count characters in textArea
var text_max = 500
$('#textarea_counter').html(text_max)

$('.maxLines').keyup(function () {
  $('.text-area_counter__container').show()
  var text_length = $('.maxLines').val().length
  var text_remaining = text_max - text_length

  $('#textarea_counter').html(text_remaining)
})

// When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
function hideTopBarOnScroll() {
  var prevScrollpos = window.pageYOffset

  // Check if we are on an user recipe page or an edit/create recipe page
  if ($('.edit-bar').length > 0) {
    $('body').addClass('has-edit-bar')
  }

  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset
    if (prevScrollpos > 80) {
      if (prevScrollpos > currentScrollPos && currentScrollPos < 600) {
        $(
          '.primary-nav, .secondary-nav, .edit-bar, .message-fixed'
        ).removeClass('hidden-nav')
        $('.secondary-nav, .primary-nav').addClass('visible-nav')
      } else {
        $('.primary-nav, .secondary-nav, .edit-bar').removeClass('visible-nav')
        $('.primary-nav, .secondary-nav, .edit-bar, .message-fixed').addClass(
          'hidden-nav'
        )
      }
    }
    prevScrollpos = currentScrollPos
  }
}

function checkFlashMessages() {
  if ($('.recipes_body .ui.message').length > 0) {
    $('.recipe-form, .recipe-wrap').addClass('has-message')
  }
}

// Remove translateY() on recipe show & form
function resetRecipeLayout() {
  $('.recipe-form, .recipe-wrap').removeClass('has-message')
}

// Hide & show global flash messages
function globalFlashMessage(type, title = '', content = '', delay = 3000) {
  var $el = $(
    `<div class="${type} ui floating message custom-alert--${type} animated fadeInUp"><div class='header'>${title}</div><p>${content}</p></div></div>`
  )

  // Display flash message
  $('.ui.container.container--main').prepend($el)

  // Hide flash message after 3sec
  setTimeout(function () {
    $el.addClass('fadeOutDown')
  }, delay)
}

// Hide GDM logo when search expand on mobile
function toggleLogo() {
  if (window.matchMedia('(max-width: 575px)').matches) {
    $('.primary-nav__search')
      .find('input')
      .focusin(function () {
        $('.primary-nav .header').addClass('fadeOut animated fastDuration')
        $('.primary-nav .header').removeClass('fadeIn')
      })

    $('.primary-nav__search')
      .find('input')
      .focusout(function () {
        $('.primary-nav .header').removeClass('fadeOut')
        $('.primary-nav .header').addClass('fadeIn')
      })
  }
}

function toggleRightTab() {
  if ('matchMedia' in window) {
    // Détection
    if (window.matchMedia('(max-width:767px)').matches) {
      $('.panel.right').removeClass('active')
    } else {
      $('.panel.right').addClass('active')
    }
  }
}

function initPopsellChat(fullsize = false, is_mobile = false) {
  const $popsellApi = $('#popsell_ambassador_api')
  let id_chatroom = helpers.getUrlParameter('id_chatroom')
  if ($popsellApi.length) {
    $.popsellchat = $(document).popsellchat({
      ambassador_api_key: $popsellApi.data('amb_key'),
      log_active: false,
      load_css: true,
      position: 'right',
      fullsize: fullsize,
      is_mobile: is_mobile,
      api_path: $popsellApi.data('path'),
      id_chatroom: id_chatroom == '' ? false : id_chatroom,
    })
  }
}

function detectSizeForPopsellChat() {
  if ('matchMedia' in window) {
    // Détection
    if (window.matchMedia('(max-width:767px)').matches) {
      $('#popsellchat_chatrooms').removeClass('popsell-chat-embedded')
      $('#popsellchat_chatrooms').addClass('popsell-chat-fullsize')
      initPopsellChat(true, true)
    } else {
      initPopsellChat()
    }
  }
}

let windowWidth = $(window).width()

window.addEventListener(
  'resize',
  function () {
    if ($(window).width() != windowWidth) {
      windowWidth = $(window).width()

      toggleRightTab()
    }
  },
  false
)

export { maxLinesForTextArea, globalFlashMessage }
