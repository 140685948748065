// app/javascript/recipes/filtering/components/thematics.js


const thematics = {
  header: $('h3.source_thematics'),
  dropdown: $('div.dropdown.filtering_thematics'),
  validateBtn: $('div.filtering.validate.thematics'),
  eraseBtn: $('div.filtering.erase.thematics'),
  semanticCheckedCheckboxes: () => $('.checkbox.thematics.checked'),
  deleteFilterBtn: () => $('.ui.label[data-classname="thematic"]'),
  checkedThematics: () => {
    let thematicsFilter = '&thematics=';
    const selectedThematics = thematics.semanticCheckedCheckboxes().map((index, checkbox) => checkbox.getAttribute('data-thematic-label')).get().join(',');
    if (selectedThematics !== '') {
      thematics.dropdown.addClass('custom-dropdown--active');
      thematicsFilter += selectedThematics;
      return thematicsFilter;
    }
    thematics.dropdown.removeClass('custom-dropdown--active');
    return '';
  },
};

export default thematics;
