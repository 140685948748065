// app/javascript/recipes/filtering/triggers/global.js

import actions from '../actions';
import components from '../components/global';

document.addEventListener('DOMContentLoaded', () => {
  actions.global.hideLockedParameter();
  if (components.reinitializeAllBtn()) {
    components.reinitializeAllBtn().addEventListener('click', () => {
      actions.global.reinitializeAllFilters();
      components.filtersListWrap().classList.toggle('visible');
    });
  }
});