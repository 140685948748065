// app/javascript/recipes/filtering/components/index.js

import categories from './categories';
import thematics from './thematics';
import verified from './verified';
import icookin from './icookin';
import difficulty from './difficulty.js.erb';
import price from './price.js.erb';
import others from './others';
import order from './order';
import users from './users';
import ingredients from './ingredients';
import products from './products';
import prepare_time from './prepare_time.js.erb';
import global from './global';
import productCategories from './product_categories'

const components = {
  categories,
  productCategories,
  thematics,
  verified,
  icookin,
  difficulty,
  price,
  others,
  order,
  ingredients,
  products,
  users,
  prepare_time,
  global,
};

export default components;
