// app/javascript/recipes/filtering/components/verified.js


const verified = {
  checkbox: $('.checkbox.filtering.validate.verified_recipes'),
  isVerifiedCheckbox: () => {
    let isVerifiedFilter = '&is_verified=';
    const isVerified = verified.checkbox.hasClass('checked');
    isVerifiedFilter += 'true';
    return isVerified === true ? isVerifiedFilter : '';
  },
};

export default verified;
