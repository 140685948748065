// app/javascript/recipes/filtering/triggers/thematics.js

import thematics from '../components/thematics';
import actions from '../actions';

document.addEventListener('DOMContentLoaded', () => {
  actions.thematics.setDropdown();
  thematics.validateBtn.on('click', () => {
    thematics.dropdown.dropdown('hide');
  });

  thematics.eraseBtn.on('click', () => {
    actions.thematics.eraseCheckboxes();
    thematics.dropdown.dropdown('hide');
  });

  document.addEventListener('click', (e) => {
    let target;
    if (e.target.classList.value.includes('ui label')) target = e.target;
    if (e.target.classList.value.includes('delete icon filter')) target = e.target.parentNode;
    if (target == null) return false;
    if (target.getAttribute('data-classname') !== 'thematic') return false;
    actions.thematics.eraseCheckbox(target);
    return true;
  });
});
