// app/javascript/users/follow_ups/actions/index.js.erb

import components from '../components';

const actions = {
  openFollowUpsModal: () => {
    components.followUpsModal().modal('refresh').modal('show');
  },
  openFollowersModal: () => {
    components.followersModal().modal('refresh').modal('show');
  },
  unfollowUser: (unfollowBtn) => {
    let userToUnfollow = unfollowBtn.data('slug');
    let successMessageDestroy = "Désabonné de"
    $.ajax({
      url: `/profil/${userToUnfollow}/destroy_user_relationship`,
      contentType: 'application/javascript',
      headers: { 'X-CSRF-Token': $.token },
      type: 'DELETE',
      statusCode: {
        204: () => {
          unfollowBtn.hide();
          unfollowBtn.next().show();
          actions.reduceFollowUpsCount();
          globalFlashMessage('success', `${successMessageDestroy}&nbsp;${userToUnfollow} <i class="check icon"></i>`);
        },
      },
    });
  },
  followUser: (followBtn) => {
    let userToFollow = followBtn.data('slug');
    let successMessageCreate = "Abonné à"
    $.ajax({
      url: `/profil/${userToFollow}/create_user_relationship`,
      contentType: 'application/javascript',
      headers: { 'X-CSRF-Token': $.token },
      type: 'POST',
      statusCode: {
        204: () => {
          followBtn.hide();
          followBtn.prev().show();
          actions.augmentFollowUpsCount();
          globalFlashMessage('success', `${successMessageCreate}&nbsp;${userToFollow} <i class="check icon"></i>`);
        },
      },
    });
  },
  reduceFollowUpsCount: () => {
    if (components.followUpsCount().html() === undefined) return;
    const followUpsText = components.followUpsCount().html().split(' ')[1];
    let count = parseInt(components.followUpsCount().html().split(' ')[0], 10);
    components.followUpsCount().html(`${count -= 1} ${followUpsText}`);
  },
  augmentFollowUpsCount: () => {
    if (components.followUpsCount().html() === undefined) return;
    const followUpsText = components.followUpsCount().html().split(' ')[1];
    let count = parseInt(components.followUpsCount().html().split(' ')[0], 10);
    components.followUpsCount().html(`${count += 1} ${followUpsText}`);
  },
};


export default actions;
